import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import Pages from "../../components/Pages";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import { formatURL, removePreloader } from "../../helpers";
import { PureCarousel } from "../../components/PureReactCarousel";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { Share } from "../../widgets";
import * as TargetBanner from "../../components/TargetBanner";
import { MEDIA_PAGE_MAX_UPDATE, MEDIA_PAGE_CONTENT, MAIN_SETTINGS } from "../../queries/queries.graphql";

import "./Media.scss";

export const query = graphql`
	query mediaData {
		hasura {
			...MediaPageContent
			...Banners
		}
	}
`;

export default function Media({ data }) {
	const [hasuraData, setHasuraData] = useState(get(data, "hasura", {}));
	const [mediaPageSettings, setMediaPageSettings] = useState(get(data, "hasura.main_page[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.v_media_updated_at[0].max_updated_at", new Date()));
	const mediaSettingsMaxUpdated = new Date(get(data, "hasura.main_page_aggregate.aggregate.max.updated_at", new Date()));

	const mediaDataOptions = {
		displayName: "MediaPageQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(MEDIA_PAGE_MAX_UPDATE);

	const [loadMediaData, {
		called: mediaDataCalled,
		loading: mediaDataLoading,
		data: mediaData,
		error: mediaDataError,
	}] = useLazyQuery(MEDIA_PAGE_CONTENT, mediaDataOptions);

	const [loadMediaPageSettingsData, {
		called: mediaPageSettingsCalled,
		loading: mediaPageSettingsLoading,
		data: mediaPageSettingsData,
		error: mediaPageSettingsError,
	}] = useLazyQuery(MAIN_SETTINGS,
		{
			fetchPolicy: "cache-and-network",
			ssr: false,
		});

	function makeData(data = [], type) {
		return data.map(({ title_short, title_full, page_title_short, title, slug, main_image_preview, main_image }) => {
			return {
				path: formatURL(`media/${type}/${slug}`),
				src: get(main_image_preview, "src", get(main_image, "src", "")),
				title: title_short || title_full || title || page_title_short,
			};
		});
	}

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_media_updated_at[0].max_updated_at", new Date()));
		const currentMediaPageSettingsMaxUpdated = new Date(get(maxUpdateData, "main_page_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadMediaData();
			} else {
				removePreloader();
			}

			if (+currentMediaPageSettingsMaxUpdated !== +mediaSettingsMaxUpdated) {
				loadMediaPageSettingsData();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load MediaPageMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (mediaData && !mediaDataError) {
			setHasuraData(mediaData);
			removePreloader(mediaDataCalled);
		} else if (mediaDataError) {
			console.error("Invalid load MediaPageData", { mediaDataError });
			removePreloader();
		}
	}, [mediaDataLoading]);

	useEffect(() => {
		if (mediaPageSettingsData && !mediaPageSettingsError) {
			setMediaPageSettings(get(mediaPageSettingsData, "main_page[0]", {}));
			removePreloader(mediaPageSettingsCalled);
		} else if (mediaPageSettingsError) {
			console.error("Invalid load MediaPageSettingsData", { mediaPageSettingsError });
			removePreloader();
		}
	}, [mediaPageSettingsLoading]);

	const url = "media";
	const photos = makeData(get(hasuraData, "v_all_media_photos", []), "photos");
	const videos = makeData(get(hasuraData, "v_media_videos", []), "videos");
	const videoCategories = makeData(get(hasuraData, "v_video_categories", []), "videos");
	const audioCategories = makeData(get(hasuraData, "audio_categories", []), "audioguides");
	const audios = makeData(get(hasuraData, "media_audios", []), "audios");
	const books = makeData(get(hasuraData, "books", []), "books");
	const radio = makeData(get(hasuraData, "radio", []), "radio");
	const tv_obraz = makeData(get(hasuraData, "media_video_categories", []), "tv_obraz");
	const virtualTour = makeData(get(hasuraData, "virtual_tours", []), "3d-tours");
	const banners = get(data, "hasura.banners", []);
	const entity = get(seoData, "media_page", {});
	const rediorecords = makeData(get(hasuraData, "v_media_radio_records", []), "radio-records");

	const bannerBlock = banners.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(/media/)).map(banner => {
		return React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		});
	});

	const auduoGuidesContent =
		<ContentBlock key={"AudioguidesGallery"} mount={audioCategories && !!audioCategories.length}>
			<div className={"container media__audioCategories"}>
				<TextBlock
					title={"Аудиогиды"}
					listLink={"/media/audioguides"}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={audioCategories}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 4,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 1136,
									slidesPerView: 4,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const videoCategoriesContent =
		<ContentBlock key={"VideosGallery"} mount={videoCategories && !!videoCategories.length}>
			<div className={"container media__audioCategories"}>
				<TextBlock
					title={"Видео"}
					listLink={"/media/videos"}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={videoCategories}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 2,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 3,
								},
								{
									width: 1136,
									slidesPerView: 4,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const virtualToursContent =
		<ContentBlock key={"Virtual_tours"} mount={virtualTour && !!virtualTour.length}>
			<div className={"container"} >
				<TextBlock
					title={"Виртуальные экскурсии"}
					id={"Virtual-Tours"}
					list={"Все виртуальные экскурсии"}
					listLink={"/3d-tours"}
					allowBtn={virtualTour && virtualTour.length > 2}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={virtualTour}
						mediaSize={["100%", 300]}
						slideRatio={{
							naturalSlideWidth: 2,
							naturalSlideHeight: 1,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 1,
							naturalSlideHeight: 0.8,
						}}
						params={{
							slidesPerView: 2,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 576,
									slidesPerView: 2,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 1136,
									slidesPerView: 2,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const photoGalleryContent =
		<ContentBlock key={"PhotoGallery"} mount={photos && !!photos.length}>
			<div className={"container"}>
				<TextBlock
					title={"Фоторепортажи"}
					list={"Все фоторепортажи"}
					listLink={"/photoalbums"}
					allowBtn={photos && photos.length > 4}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={photos}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 3,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 3,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 1136,
									slidesPerView: 3,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const tvObrazContent =
		<ContentBlock key={"TV"} mount={tv_obraz && !!tv_obraz.length}>
			<div className={"container"}>
				<TextBlock
					title={"ТВ-образ"}
					list={"Все программы"}
					listLink={"/tv-obraz"}
					allowBtn={tv_obraz && tv_obraz.length > 3}
				>
					<PureCarousel
						type={"SlideNews"}
						items={tv_obraz}
						mediaSize={["100%", 270]}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 2.5,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 1,
							naturalSlideHeight: 0.9,
						}}
						params={{
							slidesPerView: 3,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 950,
									slidesPerView: 3,
								}],
							btnStyle: {
								top: "42%",
							},
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const radioContent =
		<ContentBlock key={"Radio"} mount={radio && !!radio.length}>
			<div className={"container"}>
				<TextBlock
					title={"Радио"}
					list={"Все радиостанции"}
					listLink={"/radios"}
					allowBtn={radio && radio.length > 3}
				>
					<PureCarousel
						type={"SlideNews"}
						items={radio}
						mediaSize={["100%", 270]}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 1,
							naturalSlideHeight: 0.9,
						}}
						params={{
							slidesPerView: 2,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 950,
									slidesPerView: 3,
								}],
							btnStyle: {
								top: "42%",
							},
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const radioLiveContent =
		<ContentBlock key={"Radio"} mount={rediorecords.length}>
			<div className={"container"}>
				<TextBlock
					title={"Радиоэфиры"}
					list={"Все радиоэфиры"}
					listLink={"/radio-records"}
					allowBtn={true}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={rediorecords}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 3,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 3,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 1136,
									slidesPerView: 3,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const booksContents =
		<ContentBlock key={"books"} mount={books && !!books.length}>
			<div className={"container"}>
				<TextBlock title={"Книги"}>
					<PureCarousel
						type={"SlideNews"}
						items={books}
						mediaSize={["100%", 310]}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 5,
						}}
						params={{
							slidesPerView: 5,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 2,
								},
								{
									width: 768,
									slidesPerView: 3,
								},
								{
									width: 949,
									slidesPerView: 4,
								},
								{
									width: 1367,
									slidesPerView: 5,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const audioContent =
		<ContentBlock key={"AudioGallery"} mount={audios && !!audios.length}>
			<div className={"container"}>
				<TextBlock title={"Аудио"}>
					<PureCarousel
						type={"SlideLinks"}
						items={audios}
						slideRatio={{
							naturalSlideWidth: 2,
							naturalSlideHeight: 1,
						}}
						params={{
							slidesPerView: 2,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const videoContent =
		<ContentBlock key={"VideoGallery"} mount={videos && !!videos.length}>
			<div className={"container"}>
				<TextBlock
					title={"Видео"}
					list={"Все видео"}
					listLink={"/videos"}
					allowBtn={videos && videos.length > 2}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={videos}

						slideRatio={{
							naturalSlideWidth: 2,
							naturalSlideHeight: 1,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 1,
							naturalSlideHeight: 0.8,
						}}
						params={{
							slidesPerView: 2,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>;

	const pageBlocks = {
		auduoGuidesContent,
		videoContent,
		videoCategoriesContent,
		virtualToursContent,
		photoGalleryContent,
		tvObrazContent,
		radioContent,
		booksContents,
		audioContent,
		radioLiveContent,
		"default": null,
	};

	return (
		<Pages
			entity={entity}
			url={url}
		>
			{
				!!bannerBlock.length && (
					<ContentBlock key={"banner"}>
						<div className={"banner__main-block d-noscript-none"}>{bannerBlock}</div>
					</ContentBlock>
				)
			}
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Медиатека"} />
				</div>
			</ContentBlock>

			<ContentBlock key={"media-page"} className={"media-page"}>
				<div className={"container"}>
					<TextBlock title={"Медиатека"} />
				</div>
			</ContentBlock>

			{
				get(mediaPageSettings, "settings.mediaPageBlocksSettings", [])
					.sort((a, b) => a.sort_order - b.sort_order)
					.reduce((content, item) => {
						if (get(item, "enabled", false)) {
							content.push(pageBlocks[get(item, "slug", "default")]);
						}

						return content;
					}, [])
			}

			<div className={"container"}>
				<Share url={url} pageTitleShort={"Медиатека"} />
			</div>
		</Pages>
	);
}

Media.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

Media.defaultProps = {
	data: {},
	pageContext: {},
};
